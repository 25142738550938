.card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.85);
}
.cardMedia {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.cardIcon {
  padding: 0 24px;
  color: #00aeef;
  font-size: 64px;
}
.cardcontent {
  width: 65%;
  padding: "16px 24px 24px";
}
.centeredgrid {
  display: flex;
  justify-content: center;
}
.servicecard {
  width: 200;
  height: "100%";
}
.titleCard {
  background-color: rgba(0, 85, 126, 0.8);
}
.listIcon {
  color: #fff;
  font-size: 40px;
}
.content {
  padding: 32px;
}
.title {
  color: #00aeef;
  font-size: 50px;
}
.subtitle {
  padding: 8px;
  color: #00557e;
}
.section {
  z-index: 3;
  background-color: rgb(0, 85, 126);
}
@media screen and (max-width: 960px) {
  .listIcon {
    font-size: 24px;
  }
  .title {
    font-size: 26px;
  }
  .subtitle {
    font-size: 18px;
  }
  .sliderContainer {
    width: 90%;
    margin-left: 24px;
  }
}

@media screen and (max-width: 480px) {
  .subtitle {
    font-size: 14px;
  }
  .content {
    padding: 18px;
  }
  .title {
    padding: 0 42px;
    font-size: 24px;
  }
}
