.what_are_we {
  padding: 5% 0 5% 8px;
  display: flex;
  gap: 10%;
}
.what_are_we_Left,
.aboutus_right {
  width: 50%;
  color: var(--000000, rgba(0, 0, 0, 0.54));
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  flex: 1;
  align-self: center;
}
.what_are_we img,
.aboutus_Section img {
  flex: 1;
  width: auto;
  height: auto;
  max-width: 50%;
}
.h2 {
  color: #1b1b1c;
  font-family: PT Sans Narrow;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.26px;
  text-transform: capitalize;
  margin: 0px 0 18px;
}
.valuesSection h2 {
  text-align: center;
}
.valueitems {
  display: flex;
}
.valueitem {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 32px;
}
.valueitems :last-child {
  padding-right: 0;
}
.valueitem :first-child {
  width: 71px;
  height: 71px;
  border-radius: 50%;
  background-color: #edf9ff;
}
.valueitem h5 {
  color: var(--00557-e, #00557e);
  font-family: PT Sans Narrow;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  margin: 24px 0;
}
.aboutus_Section {
  display: flex;
  flex: 1 1;
  gap: 10%;
  padding: 5% 8px;
}

.valueitem {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.54);
}
.aboutus_right {
  align-self: center;
}

@media screen and (max-width: 778px) {
  .what_are_we {
    flex-direction: column;
    text-align: center;
    padding-right: 16px;
  }
  .what_are_we_Left {
    width: 100%;
  }
  .h2 {
    font-size: 26px !important;
  }
  .what_are_we_Left,
  .aboutus_right {
    font-size: 16px;
    line-height: 22px;
  }
  .what_are_we img,
  .aboutus_Section img {
    /* width: 100%; */
    max-width: 100%;
  }
  .valuesSection {
    padding-top: 40px;
    margin: 24px 0;
  }
  .valueitems {
    flex-direction: column;
  }
  .valueitem {
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5% 10%;
  }
  .aboutus_Section {
    flex-direction: column-reverse;
    padding: 5% 10%;
  }
  .aboutus_right {
    width: 100%;
    text-align: center;
    padding-top: 40px;
  }
  .valueitems :last-child {
    padding-right: 10%;
  }
  .valueitem h5 {
    padding: 0 !important;
  }
}

@media screen and (max-width: 480px) {
  .aboutus_Section {
    padding: 0;
  }
  .valueitem {
    font-size: 14px;
  }
}
