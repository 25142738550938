.banner {
  /* background-image: url("../../assets/images/about-us-banner.png"); */
  width: auto;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  height: 513px;
}
.bannerTxt {
  padding: 5% 0 0 16px;
  color: var(--000000, rgba(0, 0, 0, 0.54));
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.bannerTxt div {
  width: 473px;
}
.banner h3 {
  color: var(--00557-e, #00557e);
  font-family: PT Sans Narrow;
  font-size: 63px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.26px;
  text-transform: capitalize;
  margin-bottom: 16px;
}
@media screen and (max-width: 778px) {
  .banner {
    background-image: unset;
    background-color: #f4f6f7;
    text-align: center;
    height: auto;
    min-height: 250px;
  }
  .banner h3 {
    font-size: 32px;
  }
  .bannerTxt {
    font-size: 16px;
    text-align: center;
    padding: 5%;
  }
  .bannerTxt div {
    width: auto;
    padding: 5%;
  }
  .commonSection > :first-child {
    padding: 0 !important;
  }
}
