@media screen and (max-width: 960px) {
  .common h5 {
    font-size: 20px;
  }
  .common h6 {
    font-size: 18px;
  }
  .addOnList {
    flex-direction: column;
    align-items: unset;
    justify-content: unset;
  }
  .addOnList > div {
    display: flex;
  }
}
