.form,
.form-error {
    width: 100%;
    height: 100%;
    position: relative;
    padding-bottom: 10px;
}

.form-control,
.form-control-error {
    width: 100%;
    border: none;
    font: inherit;
    border: 0;
    margin: 0;
    padding: 6px 0 7px;
    display: block;
    min-width: 0;
    flex-grow: 1;
    box-sizing: content-box;
    background: none;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    margin-top: 14px;
}

.form-control:focus,
.form-control-error:focus {
    outline: none;
    border: none;
    border-bottom: 1.5px solid rgb(133, 133, 133);
}

.form label,
.form-error label {
    position: absolute;
    top: 10px;
    left: 0%;
    transition: top .3s;
    font-weight: 300;
}

.form-error label {
    color: #f44336;
    font-weight: 400;
}

.form label {
    color: rgb(89, 86, 86);
    cursor: text;
}

.form-control:focus~label,
.form-control-error:focus~label {
    top: -10px;
    font-size: 12px;
}

.form-control:not(:placeholder-shown)~label,
.form-control-error:not(:placeholder-shown)~label {
    top: -10px;
    font-size: 12px;
}

.form-control::placeholder,
.form-control-error::placeholder {
    color: transparent;
}

.form-control-error {
    border-bottom: 1.5px solid red !important;
}

.form-control {
    border-bottom: 1.5px solid rgb(133, 133, 133) !important;
}
