.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
  box-shadow: none;
}
.dropdown > span {
  display: unset;
}
.dropdown > svg {
  margin-bottom: -4px;
}
.menuItem {
  font-family: "PT Sans Narrow", "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}
@media screen and (max-width: 960px) {
  .header {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    background-color: #00557e;
  }
}

@media screen and (min-width: 600px) {
  .hidden {
    display: none;
  }
}
