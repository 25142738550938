.banner {
  display: flex;
  /* background: url("../../assets/images/Electrician.png"),
    lightgray 50% / cover no-repeat; */
  /* background-color: lightgray 50%; */
  background-color: #f4f6f7;
}
.bannerLeft {
  width: 50%;
  z-index: 50;
  padding: 8% 16px;
}
.bannerRight {
  width: 50%;
  padding-right: 16px;
}
.home {
  display: block;
  margin-bottom: 100px;
  margin-top: -16px;
}
.Playlist {
  /* padding: 80px 6% 0; */
}
.videoGrid {
  overflow-y: auto;
  padding-left: 24px;
}
.PlayerGrid {
  display: flex;
  max-height: 415px;
  width: 100%;
  margin-top: 56px;
}
.PlayerGrid :first-child {
  flex: 0 0 60%;
}
.videoDesc {
  display: flex;
  width: 100%;
  overflow-y: hidden;
  margin: 0px 0px 16px;
}
.videoDesc img {
  flex: 0 0 35% !important;
  padding-right: 16px;
  margin: 8px 0;
  cursor: pointer;
  width: 15%;
  height: 111px;
}
.videotitle {
  flex: 0 0 64%;
  margin-top: 8px;
  overflow: hidden;
}
.videotitle h3 {
  color: var(--00557-e, #00557e);
  font-family: PT Sans Narrow;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  cursor: pointer;
}
.videotitle p {
  color: #555;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  max-height: 32px;
  overflow: hidden;
  margin: 4px 16px 0 0px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.videotitle a {
  overflow: hidden;
  color: #00557e;
  text-overflow: ellipsis;
  font-family: "Roboto", "Helvetica", "sans-serif";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  cursor: pointer;
}
.title {
  color: var(--00557-e, #00557e);
  font-family: PT Sans Narrow;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding-top: 8px;
}
.description div {
  color: rgba(0, 0, 0, 0.54);
  font-family: "Roboto", "Helvetica", "sans-serif";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-overflow: clip;
  display: -webkit-box;
  max-height: 54px;
  overflow: hidden;
}
.seeless {
}
.description a {
  position: absolute;
  right: 0;
  bottom: -3px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0.2em 0.5em;
  text-decoration: none;
  color: #00557e;
  cursor: pointer;
  background: #f4f6f7;
  font-weight: 450;
  padding-left: 15px;
}
.description {
  position: relative;
  display: flex;
  align-items: flex-start;
}
.fulldesc div {
  max-height: max-content;
  overflow: unset;
  display: block;
}
.videoGrid::-webkit-scrollbar {
  scrollbar-color: #999 #333;
}
.PlaylistSection {
  padding: 80px 16px 0;
}
.line {
  height: 1px;
  background: #dadada;
  margin-top: 48px;
}
.playing {
  background-color: #dadada;
}
.singleseemore {
  background: #fff !important;
}
.singlepage_videoList {
  margin-top: 32px !important;
}

@media screen and (max-width: 992px) {
  .seemoreBtn {
    all: unset;
    color: var(--00557-e, #00557e);
    text-align: center;
    font-family: PT Sans Narrow;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
    text-transform: capitalize;
    display: flex;
    margin: auto;
    margin-top: 48px;
  }

  .videoGrid {
    margin-top: 32px;
    padding-left: 0;
  }
  .banner,
  .PlayerGrid {
    flex-direction: column;
  }
  .bannerLeft,
  .Playlist {
    width: 100%;
    text-align: center;
  }
  .videotitle h3 {
    line-height: 20px;
  }
  .videotitle p {
    max-height: 32px;
    -webkit-line-clamp: 2;
  }
  .PlaylistSection {
    padding-top: 40px;
  }
  .PlayerGrid {
    margin-top: 32px;
  }
}

@media screen and (max-width: 480px) {
  .bannerLeft {
    padding: 100px 0;
  }
  .bannerLeft h2,
  .Playlist h3 {
    font-size: 32px;
  }
  .bannerLeft p,
  .Playlist p,
  .description div {
    font-size: 16px;
  }
  .description {
    font-size: 16px;
    line-height: 20px;
  }
  .description div {
    max-height: 48px;
  }
  .videoDesc {
    overflow: hidden;
  }
  .videoDesc img {
    height: auto;
    width: 46%;
  }
  .videotitle {
    flex: 0 0 54%;
    width: auto;
    overflow: hidden;
  }
  .seemoreBtn {
    font-size: 16px;
  }
  .PlaylistSection {
    padding-left: 0;
    padding-right: 0;
  }
  .bannerLeft {
    padding-left: 0;
    padding-right: 0;
  }
}
