.icon {
  font-size: 80px;
}
.card,
.transparent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 24px;
  height: 100%;
}
.card {
  color: #00557e;
}
.transparent {
  /* background-color: transparent(0, 2, #00557e); */
  background-color: rgba(0, 85, 126, 0.8);
  color: #fff;
}
.image {
  width: 100%;
}
@media screen and (min-width: 1400px) {
  .Button {
    width: 174px;
    /* height: 80px; */
  }
}
